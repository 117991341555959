import { Link } from '@remix-run/react';

export default function Header({
  isAuthenticated = false,
}: {
  isAuthenticated?: boolean;
}) {
  return (
    <div className="bg-[#FE5000] h-20 px-4 w-full">
      <div className="h-20 flex gap-2 items-center justify-between mx-auto max-w-7xl">
        <div className="shrink-0 max-[400px]:w-[57px] overflow-hidden">
          <img
            src="/mrwash-header-logo.png"
            alt="Mr Wash Logo"
            className="h-[49px] w-[177px] object-scale-down max-w-[177px]"
          />
        </div>
        <div className="ml-auto flex gap-2 md:gap-4">
          {!isAuthenticated && (
            <Link className="text-white text-sm" to="/manage-account">
              Manage Account
            </Link>
          )}
          {isAuthenticated && (
            <Link className="text-white text-sm" to="/kinde-auth/logout">
              Logout
            </Link>
          )}
          <Link className="text-white text-sm" to="/support">
            Support
          </Link>
        </div>
      </div>
    </div>
  );
}
